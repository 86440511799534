const POKER_URL = '/poker'
const pokerTagsAndPrefixes = [
  {
    tags: ['learn-poker'],
    prefix: `${POKER_URL}/${process.env.GATSBY_CMS_LEARN_POKER_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/PokerLearn',
  },
  {
    tags: ['Strategy'],
    prefix: `${POKER_URL}/${process.env.GATSBY_CMS_POKER_STRATEGY_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/PokerStrategy1',
  },
  {
    tags: ['Books'],
    prefix: `${POKER_URL}/${process.env.GATSBY_CMS_POKER_BOOKS_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/PokerBooks1',
  },
  {
    tags: ['Poker-Terms'],
    prefix: `${POKER_URL}/${process.env.GATSBY_CMS_POKER_TERMS_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/PokerTerms1',
  },
  {
    tags: ['Poker-hands'],
    prefix: `${POKER_URL}/${process.env.GATSBY_CMS_POKER_HANDS_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/PokerHands1',
  },
]

const NEWS_URL = '/news/'
const newsTagsAndPrefixes = [
  {
    tags: ['News'],
    prefix: `${process.env.GATSBY_CMS_NEWS_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
  },
]

const RUMMY_URL = '/rummy'
const rummyTagsAndPrefixes = [
  {
    tags: ['Learn Rummy'],
    prefix: `${RUMMY_URL}/${process.env.GATSBY_CMS_LEARN_RUMMY_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
  },
]

const POOL_URL = '/casual/8-ball-pool'
const poolTagsAndPrefixes = [
  {
    tags: ['Learn Pool'],
    prefix: `${POOL_URL}`,
    one_link: 'https://getmega.onelink.me/shOD/PoolLearn',
  },
]

const CHESS_URL = '/casual/chess'
const chessTagsAndPrefixes = [
  {
    tags: ['Learn Chess'],
    prefix: `${CHESS_URL}/${process.env.GATSBY_CMS_LEARN_CHESS_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/ChessLearn',
  },
]

const CARROM_URL = '/casual/carrom'
const carromTagsAndPrefixes = [
  {
    tags: ['Learn Carrom'],
    prefix: `${CARROM_URL}/${process.env.GATSBY_CMS_LEARN_CARROM_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
  },
]

const realMoneyGamesTagsAndPrefixes = [
  {
    tags: ['#competitor_level2'],
    prefix: `/${process.env.GATSBY_CMS_REAL_MONEY_GAMES_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
    excludeTag: ['display/Rummy', 'display/Poker'],
  },
]

const rmgPokerRealMoneyTagsAndPrefixes = [
  {
    tags: ['#competitor_level2'],
    prefix: `${POKER_URL}/${process.env.GATSBY_CMS_POKER_RMG_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/PokerLearn',
    rmgTag: ['display/Poker'],
  },
]

const rmgRummyRealMoneyTagsAndPrefixes = [
  {
    tags: ['#competitor_level2'],
    prefix: `${RUMMY_URL}/${process.env.GATSBY_CMS_RUMMY_RMG_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
    rmgTag: ['display/Rummy'],
  },
]

const authorsTagsAndPrefixes = [
  {
    tags: ['#getmega_author'],
    prefix: `/${process.env.GATSBY_CMS_AUTHOR_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/Authors',
  },
]

const earnMoneyTagsAndPrefixes = [
  {
    tags: ['#earn_money'],
    prefix: `/${process.env.GATSBY_CMS_EARN_MONEY_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
  },
]

const gamblingTagsAndPrefixes = [
  {
    tags: ['#gambling'],
    prefix: `/${process.env.GATSBY_CMS_GAMBLING_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
  },
]

const listingsTagsAndPrefixes = [
  {
    tags: ['#listings'],
    prefix: `/${process.env.GATSBY_CMS_LISTINGS_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/Listings',
  },
]

const CRICKET_URL = '/casual'
const cricketTagsAndPrefixes = [
  {
    tags: ['Learn Cricket'],
    prefix: `${CRICKET_URL}/${process.env.GATSBY_CMS_LEARN_CRICKET_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/CricketLearn',
  },
]

const rummyCouponsTagsAndPrefixes = [
  {
    tags: ['#coupons'],
    prefix: `${RUMMY_URL}/${process.env.GATSBY_CMS_RUMMY_RMG_PREFIX}`,
    one_link: 'https://www.getmega.com/latest-rc',
    rmgTag: ['display/Rummy'],
  },
]

const pokerCouponsTagsAndPrefixes = [
  {
    tags: ['#coupons'],
    prefix: `${POKER_URL}/${process.env.GATSBY_CMS_POKER_RMG_PREFIX}`,
    one_link: 'https://getmega.onelink.me/shOD/PokerLearn',
    rmgTag: ['display/Poker'],
  },
]

const allBlogTags = [
  ...newsTagsAndPrefixes.flatMap(t => t.tags),
  ...pokerTagsAndPrefixes.flatMap(t => t.tags),
  ...carromTagsAndPrefixes.flatMap(t => t.tags),
  ...rummyTagsAndPrefixes.flatMap(t => t.tags),
  ...poolTagsAndPrefixes.flatMap(t => t.tags),
  ...chessTagsAndPrefixes.flatMap(t => t.tags),
  ...earnMoneyTagsAndPrefixes.flatMap(t => t.tags),
  ...realMoneyGamesTagsAndPrefixes.flatMap(t => t.tags),
  ...authorsTagsAndPrefixes.flatMap(t => t.tags),
  ...cricketTagsAndPrefixes.flatMap(t => t.tags),
  ...listingsTagsAndPrefixes.flatMap(t => t.tags),
  ...rmgPokerRealMoneyTagsAndPrefixes.flatMap(t => t.tags),
  ...rmgRummyRealMoneyTagsAndPrefixes.flatMap(t => t.tags),
  ...rummyCouponsTagsAndPrefixes.flatMap(t => t.tags),
  ...pokerCouponsTagsAndPrefixes.flatMap(t => t.tags),
]

const getAllBlogs = (posts, tagsAndPrefixes) => {
  const allBlogs = []
  for (let i = 0; i < posts.length; i += 1) {
    const post = posts[i]
    const postTags = post.node.tags.map(tg => tg.name)
    const tnp = tagsAndPrefixes.find(e =>
      e.tags.some(tag => postTags.includes(tag))
    )
    if (tnp) {
      allBlogs.push({ ...post.node, prefix: tnp.prefix })
    }
  }

  return allBlogs
}

module.exports = {
  NEWS_URL,
  newsTagsAndPrefixes,
  POKER_URL,
  pokerTagsAndPrefixes,
  CARROM_URL,
  carromTagsAndPrefixes,
  RUMMY_URL,
  rummyTagsAndPrefixes,
  POOL_URL,
  poolTagsAndPrefixes,
  CHESS_URL,
  chessTagsAndPrefixes,
  earnMoneyTagsAndPrefixes,
  gamblingTagsAndPrefixes,
  listingsTagsAndPrefixes,
  realMoneyGamesTagsAndPrefixes,
  authorsTagsAndPrefixes,
  allBlogTags,
  CRICKET_URL,
  cricketTagsAndPrefixes,
  rummyCouponsTagsAndPrefixes,
  pokerCouponsTagsAndPrefixes,
  rmgPokerRealMoneyTagsAndPrefixes,
  rmgRummyRealMoneyTagsAndPrefixes,
  getAllNews: posts => getAllBlogs(posts, newsTagsAndPrefixes),
  getAllPokerBlogs: posts => getAllBlogs(posts, pokerTagsAndPrefixes),
  getAllCarromBlogs: posts => getAllBlogs(posts, carromTagsAndPrefixes),
  getAllRummyBlogs: posts => getAllBlogs(posts, rummyTagsAndPrefixes),
  getAllPoolBlogs: posts => getAllBlogs(posts, poolTagsAndPrefixes),
  getAllChessBlogs: posts => getAllBlogs(posts, chessTagsAndPrefixes),
  getAllEarnMoneyBlogs: posts => getAllBlogs(posts, earnMoneyTagsAndPrefixes),
  getAllGamblingBlogs: posts => getAllBlogs(posts, gamblingTagsAndPrefixes),
  getAllListingsBlogs: posts => getAllBlogs(posts, listingsTagsAndPrefixes),
  getAllAuthorsBlogs: posts => getAllBlogs(posts, authorsTagsAndPrefixes),
  getAllRealMoneyGamesBlogs: posts =>
    getAllBlogs(posts, realMoneyGamesTagsAndPrefixes),
  getAllCricketBlogs: posts => getAllBlogs(posts, cricketTagsAndPrefixes),
  getAllRmgPokerRealMoneyBlogs: posts =>
    getAllBlogs(posts, rmgPokerRealMoneyTagsAndPrefixes),
  getAllRmgRummyRealMoneyBlogs: posts =>
    getAllBlogs(posts, rmgRummyRealMoneyTagsAndPrefixes),
  getAllRummyCouponsBlogs: posts =>
    getAllBlogs(posts, rummyCouponsTagsAndPrefixes),
  getAllPokerCouponsBlogs: posts =>
    getAllBlogs(posts, pokerCouponsTagsAndPrefixes),
}
